import React,{useState,} from 'react'
import { IconButton, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import {celda} from '../../Generales/TablaGeneral';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ModalmgAlbum from './ImgAlbum'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
//import { printConsole } from '../../funciones/ConsolaLog';
const useStyles = makeStyles()((theme ) => {
    return {
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 420,
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaAlbum = ({datos}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);  
    const [modalImg, setModalImg] = useState(false); 
    const [modalMedia, setModalMedia] = useState(false); 
    const abrirModalImg=(e)=> {
        e.preventDefault()
        setModalImg(true)
    }

    const generaMedia=(row)=>{
        row.forEach(element => {
            
        });
        setModalMedia(true)
    }
    function Row(props) {
        const {row} = props;        
        return (           
        <TableRow
            onClick={() => { setSelected(row) }}
            selected={selected?.Id === row.Id} className={classes.tableRow}
            classes={{hover: classes.hover, selected: classes.selected}}
        >            
            {/* celda("center", { width: 45+'px'},       
                <Tooltip title="Editar Temas">                    
                <IconButton size="small" onClick={generaMedia(row)}  
                    disabled={row.Total===0?true:false} >
                    <LibraryMusicIcon color={row.Total===0?"inherit":"primary"} />
                </IconButton>              
                </Tooltip>         
            , 1, classes.tableCell)  */ }                    
            {celda("center", {width: 45+'px'}, "", 1, classes.tableCell)}  
            {celda("center", {width: 45+'px'}, row.TipoNom, 2, classes.tableCell)}              
            {celda("center", {width: 45+'px'}, row.ContratoId, 3, classes.tableCell)}  
            {celda("center", {}, row.Titulo, 4, classes.tableCell)}   
            {celda("center", {}, 
               row.Img.length!==0?
                <IconButton size="small" onClick={abrirModalImg}>
                    <PhotoLibraryIcon/>
                </IconButton>:null
            ,5, classes.tableCell)}             
            {celda("left", {}, row.InterNom, 6, classes.tableCell)}
            {celda("left", { }, row.InterRep, 7, classes.tableCell)}
            {celda("center", {}, row.InterTel, 8, classes.tableCell)}
                
            
        </TableRow>                          
        );
    }

    const tablaCompleta = () => {

        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {celda("left", {}, "", 1, "")}
                    {celda("center", {}, "Tipo",2, "")}                                                
                    {celda("center", {}, "Contrato", 3, "")}
                    {celda("center", {}, "Titulo",4, "")}    
                    {celda("center", {}, "",5, "")}                        
                    {celda("center", {}, "Interprete/Banda", 6, "")}
                    {celda("center", {}, "Representante", 7, "")}
                    {celda("center", {}, "Teléfono",8, "")}                                                                                    
                </TableRow>
            </TableHead>
            <TableBody>                                                                 
                {datos.albums.length !== 0 && datos.albums[0].Id !== - 1 ? 
                    (datos.albums.map((row) => (
                        <Row key={row.Id} row={row}/>
                    ))) 
                : <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "", 2, classes.tableCell)}
                {celda("center", {}, "Sin", 3, classes.tableCell)}
                {celda("center", {}, "", 4, classes.tableCell)}
                {celda("center", {}, "Registros", 5, classes.tableCell)}
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "", 7, classes.tableCell)}
            </TableRow>      }
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    const modImg = modalImg? <ModalmgAlbum modalAbierto={modalImg} setModalAbierto={setModalImg} 
                                registro={selected}/>:null
  return (
    <>
        { tablaCompleta()  }
        {modImg}
    </>
  )
}

export default TablaAlbum