import React,{useState,useEffect} from 'react'
import { Box, IconButton, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow,    Avatar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import moment from 'moment';
import {celda} from '../../Generales/TablaGeneral';
import { printConsole } from '../../funciones/ConsolaLog';
import NuevoSello from './NvoSello';
import {AddCircleOutline} from '@mui/icons-material';
import EditarSello from './EditSello';
import { red, green } from '@mui/material/colors';
import CreateIcon from '@mui/icons-material/Create';
import CircleIcon from '@mui/icons-material/Circle';
import ModalImg from './ModalArchivo';
import ImageIcon from '@mui/icons-material/Image';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '50rem',
        },
        container: {
            maxHeight: 420,
            width: '50rem',
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaSello = ({datos,setContrato,Buscar}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [modalNvoSello,setModalNvoSello]= useState(false);
    const [modalEditSello,setModalEditSello]= useState(false);
    const [modalArchImg,setModalArchImg]= useState(false);

    useEffect(() => {
        printConsole(datos)        
    }, [ ])
 
    function Row(props) {
        const {row} = props;        
        return (           
        <TableRow  selected={selectedID?.Id === row.Id} 
            classes={{hover: classes.hover, selected: classes.selected}}
            className={classes.tableRow}    
            onClick={() => { 
                setSelectedID(row) 
                setContrato(row) }}                     
        >   
            {  celda("center", { },       
                <Tooltip title="Editar Sello">
                <IconButton size="small" onClick={()=>{setModalEditSello(true)}}   >
                    <CreateIcon/>
                </IconButton>              
                </Tooltip>         
            , 1, classes.tableCell)  }      
            {  celda("center", { },       
                <Tooltip title="Imagen Sello">
                <IconButton size="small" onClick={()=>{setModalArchImg(true)}}   >
                    {row.Img ==="0" ? <ImageIcon/>                    
                    :<Avatar  alt={row.Representa} src={row.Img}/>                                 }

                </IconButton>              
                </Tooltip>         
            , 10, classes.tableCell)  }                                   
            {  celda("center", { },       
                <Tooltip title={row.Status===1 ?"Activo":"Desactivado"}>                
                    <CircleIcon sx={{ color: row.Status===1 ? green[500] :red[500]}} 
                        fontSize="small"
                    />                
                </Tooltip>         
            , 9, classes.tableCell)  }                                       
            {celda("center", {width: 45+'px'}, 
                row.Id
            , 2, classes.tableCell)}  
            {celda("left", {}, row.Alias, 8, classes.tableCell)} 
            {celda("center", {width: 45+'px'}, 
                row.FReg=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.FReg).format('DD/MM/YYYY')
            , 4, classes.tableCell)}                                 
            {celda("center", {}, row.Tel, 5, classes.tableCell)} 
            {celda("left", {}, row.Nom, 3, classes.tableCell)}                             
            {celda("left", {}, row.Representa, 6, classes.tableCell)}
            {celda("left", {}, row.Email, 7, classes.tableCell)}
        </TableRow>                          
        );
    }

const tablaCompleta = () => {

    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>                
                {celda("center", {}, "",9, "")}    
                {celda("center", {}, "",10, "")}    
                {celda("center", {},  
                    <Tooltip title="Agregar Sello">
                    <IconButton onClick={()=>{setModalNvoSello(true)}} size="small" >
                        <AddCircleOutline />
                    </IconButton>
                    </Tooltip>
                , 1, "")}                                           
                {celda("center", {}, datos.length , 2, "") }                
                {celda("center", {}, "Alias",3, "")}                   
                {celda("center", {}, "FReg", 4, "") }  
                {celda("center", {}, "Telefono",6, "")}     
                {celda("center", {}, "Nombre",5, "")}                                      
                {celda("center", {}, "Representante",7, "")}     
                {celda("center", {}, "Email", 8, "")}                                                                                                            
            </TableRow>
        </TableHead>
        <TableBody>                                                                 
            {datos.length !== 0  ? 
                (datos.map((row) => (
                    row.Id!==0? <Row key={row.Id} row={row}/>:null
                ))) 
            : 
            <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "", 9, classes.tableCell)}
                {celda("center", {}, "", 2, classes.tableCell)}
                {celda("center", {}, "Sin", 3, classes.tableCell)}
                {celda("center", {}, "", 8, classes.tableCell)}
                {celda("center", {}, "", 4, classes.tableCell)}                
                {celda("center", {}, "Registros", 5, classes.tableCell)}
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "", 7, classes.tableCell)}
            </TableRow>       
            }
        </TableBody>
    </Table>
    </TableContainer>
    )
    }

    const modNvoSello= modalNvoSello ? <NuevoSello modalAbierto={modalNvoSello} Buscar={Buscar}
                                                setModalAbierto={setModalNvoSello} registro={selectedID} /> :null
    const modEditSello= modalEditSello ? <EditarSello modalAbierto={modalEditSello} Buscar={Buscar}
                                                setModalAbierto={setModalEditSello} registro={selectedID} /> :null         
    const modImg  = modalArchImg ?  <ModalImg modalAbierto={modalArchImg} setModalAbierto={setModalArchImg} 
                                        registro={selectedID} Buscar={Buscar} /> :null                                                                                        
  return (
    <Box>
        {modImg}
        {modNvoSello}
        {modEditSello}
        {  tablaCompleta()  }
    </Box>
  )
}

export default TablaSello