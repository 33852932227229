import React from 'react';
import {Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

const TableInterprete = ({abrirModalNuevo, interpretes, handleEditar, abrirModalImagen}) => {
    return (
        <TableContainer component={Paper} sx={{maxHeight: '80vh'}}>
            <Table sx={{width: '100%'}} aria-label="interpretes table">
                <TableHead sx={{
                    backgroundColor: (theme) => theme.palette.secondary.light,
                    padding: '0.2rem'
                }}>
                    <TableRow>
                        <TableCell align="center" sx={{
                            padding: '0.2rem',
                            color: (theme) => theme.palette.secondary.contrastText
                        }}> <Tooltip title={'Nuevo Interprete/Banda'} arrow placement="top">
                            <IconButton onClick={abrirModalNuevo} color={'inherit'} size={'small'}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                        </TableCell>

                        <TableCell align="center" sx={{
                            padding: '0.2rem',
                            color: (theme) => theme.palette.secondary.contrastText
                        }}>Interprete/Banda</TableCell>
                        <TableCell align="center" sx={{
                            padding: '0.2rem',
                            color: (theme) => theme.palette.secondary.contrastText
                        }}>Representante</TableCell>
                        <TableCell align="center" sx={{
                            padding: '0.2rem',
                            color: (theme) => theme.palette.secondary.contrastText
                        }}>Teléfono</TableCell>
                        <TableCell align="center" sx={{
                            padding: '0.2rem',
                            color: (theme) => theme.palette.secondary.contrastText
                        }}>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {interpretes.length === 0 ? <TableRow hover sx={{
                            '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                                backgroundColor: (theme) => theme.palette.secondary.light,
                                color: (theme) => theme.palette.secondary.contrastText,
                            }, '&:last-child td, &:last-child th': {border: 0},
                        }}>
                            <TableCell component="th" scope="row" sx={{padding: '0.2rem', color: 'red'}}
                                       colSpan={"100%"}
                                       align={'center'}>
                                <h4>No existen datos </h4>
                            </TableCell>
                        </TableRow>

                        : interpretes.map((row, index) => (<TableRow hover key={index} sx={{
                            '&:hover .MuiTableCell-root': {
                                backgroundColor: (theme) => theme.palette.secondary.light,
                                color: (theme) => theme.palette.secondary.contrastText,
                            }, '&:last-child td, &:last-child th': {border: 0},
                        }}>
                            <TableCell component="th" scope="row" align={'center'}
                                       sx={{padding: '0.2rem'}}><Tooltip title={'Editar Interprete/Banda'}
                                                                         arrow
                                                                         placement="top">
                                <IconButton onClick={() => handleEditar(row.Id)} size={'small'} color={'inherit'}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                                <Tooltip title={'Editar Imagen'} arrow placement="top">
                                    <IconButton onClick={() => abrirModalImagen(row.Id)} size={'small'}>
                                        <Avatar alt={row.Representa} src={row.Img} sx={{ width: 32, height: 32 }}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell component="th" scope="row" align={'center'}
                                       sx={{padding: '0.2rem', color: row.Nom === '' ? 'tomato' : null}}>{row.Nom === '' ? 'Sin Interprete/Banda' : row.Nom}</TableCell>
                            <TableCell component="th" scope="row" align={'center'}
                                       sx={{padding: '0.2rem', color: row.Representa === '' ? 'tomato' : null}}>{row.Representa === '' ? 'Sin Representante' : row.Representa}</TableCell>
                            <TableCell component="th" scope="row" align={'center'}
                                       sx={{padding: '0.2rem', color: row.Tel === '' ? 'tomato' : null}}>{row.Tel === '' ? 'Sin Telefono' : row.Tel}</TableCell>
                            <TableCell component="th" scope="row" align={'center'}
                                       sx={{padding: '0.2rem', color: row.Email === '' ? 'tomato' : null}}>{row.Email === '' ? 'Sin Email' : row.Email}</TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableInterprete;
