import React,{useState,useEffect} from 'react'
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {TextField,Stack ,MenuItem,FormControl,Select,InputLabel,
      Box,Grid  ,FormControlLabel,Checkbox, Typography ,IconButton }from '@mui/material';
import FormDialog from '../../Layout/FormDialog'
import {llamadaApiToken,ErrorGeneral,llamadaApiCarga } from '../../funciones/ClienteAxios';
import {printConsole} from '../../funciones/ConsolaLog'
import moment from 'moment';
import {toast} from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import {Search as SearchIcon} from '@mui/icons-material/';
const EditarContrato = ({modalAbierto, setModalAbierto, registro,Buscar}) => {
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const history = useNavigate();
  const usuId =localStorage.getItem('UsuId') ; 
  const hoy=moment(new Date()).format('YYYY-MM-DD')
  const source = axios.CancelToken.source();            
  const [espera,setEspera]= useState(false);
  const [listTipo,setListTipo]= useState([])  
  const [listSello,setListSello]= useState([])  
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)  
  const [listInterprete,setListInterprete]= useState([])  
  const [contadorToast,setContadorToast]= useState(0)  
  const [contrato,setContrato]= useState({Tipo:"", Sello:"",
    FIni:hoy, FFin:hoy,
    FFirma:hoy+"T09:00",Interprete:"",
    Representa:"",
    Descripcion:"",auxInter:"",
    Observacion:"",motivo:"",fchCancela:""})
  const{Tipo, Sello, FIni, FFin, FFirma, Representa, Descripcion,Observacion,motivo,fchCancela,auxInter,Interprete}=contrato
  const [cancelar,setCancelar]= useState(false)
  const [cargaInter,setCargaInter]= useState(false);
  useEffect(() => {
    setContrato({...contrato,Tipo:registro.TipoId, Sello:registro.SelloId,
      FIni:registro.Fini.substring(0,10), FFin:registro.Ffin.substring(0,10),
      FFirma:registro.Ffirma.substring(0,16),
      Representa:registro.Representante,
      Descripcion:registro.Descr,
      Observacion:registro.Observ,auxInter:registro.InterNom,
      motivo:registro.MotCancel,Interprete:registro.InterId,
      fchCancela:registro.Fcancel.substring(0,4)!=="1900"? moment(registro.Fcancel).format('DD/MM/YYYY') :""
    })
    setCancelar(registro.MotCancel!==""? true:false)
    const llenarListTipo = () => {      
      let data = qs.stringify({
        usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "/contrato-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListTipo(aux);                
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    const llenarListSello = () => {      
      let data = qs.stringify({
        Usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "/contrato-sello";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListSello(aux);   
            printConsole(aux)             
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };

    llenarListTipo()
    llenarListSello()
    busqInterprete(registro.InterNom)

    return () => {
      source.cancel();
    }

  }, [registro])

  const busqInterprete = (aux) => {                       
    let data = qs.stringify({              
        Usuario:usuId, 
        Cadena:aux.length===0?auxInter:aux,
        idDispositivo: auxDispV,
    });
    
    let url = "interprete-search";
    function respuesta(auxiliar){            
      if ( auxiliar[0].Id &&auxiliar[0].Id>0) 
      {                 
        setListInterprete(auxiliar)
      }             
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setCargaInter,history);
  }; 

  const llamadaApi = () => {                   
    
    let data = qs.stringify({
        Llave:registro.Id,
        Usuario:usuId, Tipo:Tipo, Sello: Sello.length!==0?Sello:0,
        Cancela:cancelar===true?1: 0,
        FIni:`${FIni}T00:00:00`,
        FFin:`${FFin}T23:59:59`,
        FFirma:`${FFirma}:00`,
        Representa:Representa,
        Descr:Descripcion,
        Observ:Observacion,
        Motivo:motivo,
        Interprete:Interprete.length!==0?Interprete:0,
        idDispositivo: auxDispV,
    });
    
    let url = "contrato-edit-1-1";
    function respuesta(auxiliar){            
      if ( auxiliar) 
      {                 
        Buscar()
        let mensaje="Se edito correctamente el registro"
        //setTipoAdvertencia("success")      
        //setMensaje(mensaje);
        //setAviso(true)     
        toast.success(`${mensaje}`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-env`
        })  

        setModalAbierto(false)    
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
  };  

  const onChangeDate=(e)=>{
    if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined 
      
    ) {
      if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
      {
        if (e.target.name==="FIni" ) {
          setContrato({...contrato,[e.target.name] : e.target.value,
            FFirma:e.target.value+"T09:00",
          })    
        }else{
          setContrato({...contrato,[e.target.name] : e.target.value})  
        }
        
      }      
    }
  }

  const onChange=(e)=>{
    
    if (e.target.name==="Tipo") 
    {
      setContrato({...contrato,[e.target.name] : e.target.value,Sello : "",
      Interprete:"",auxInter:""})
    }
    else
    {
      setContrato({...contrato,[e.target.name] : e.target.value})
    }
    
  }

  const selectTipo= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
    <InputLabel id="demo-select-small">Tipo</InputLabel>
    <Select
      labelId="demo-select-small" id="demo-select-small" name="Tipo"
      value={Tipo} label="Tipo" onChange={onChange}
    >      
      {listTipo.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Alias}</MenuItem>)}
    </Select>
  </FormControl>

  const selectSello= <FormControl sx={{  width: "17rem"}} size="small">
    <InputLabel id="demo-select-small">Sello</InputLabel>
    <Select
      labelId="demo-select-small" id="demo-select-small" name="Sello"
      value={Sello} label="Sello" onChange={onChange}
    >      
      {listSello.map((Sello)=><MenuItem value={Sello.Id}>{Sello.Nom}</MenuItem>)}
    </Select>
  </FormControl>

  const verificaNulos = (campo,descripcion)=>{
    let respuesta =false;              
    if( (typeof campo !== 'number' && typeof campo === 'string' 
        && (campo.trim() === '' || campo.trim() === null) )
        || campo === null
    ) 
    { let mensaje='No se lleno el campo '+descripcion      
      toast.warning(`${mensaje}`,{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `error-env ${contadorToast}`
      })  
      setContadorToast(contadorToast+1)
      respuesta =true
    }    
    return(respuesta);
  }

  const guardarDatos=()=>{

    if ( !verificaNulos(Tipo,"Tipo") && 
      (Tipo===1 && !verificaNulos(Sello,"Sello") && !verificaNulos(Interprete,"Interprete")  || Tipo!==1 )  )      
    {      
      if (Representa.length>=3 ||  !verificaNulos("","Representante")) 
      {
        if ((Descripcion.length>=3  ||  !verificaNulos("","Descripción") )
         && ( (cancelar && !verificaNulos(motivo,"Motivo") )|| cancelar===false)) {         
          llamadaApi()
        }
      }
    }
  }

  const handleChange = (event) => {
    
    setCancelar(event.target.checked);
  };
  const selectInter= <FormControl sx={{   width: "15.5rem" }} size="small">
  <InputLabel id="demo-select-small">Interprete</InputLabel>
  <Select
    labelId="Interprete-small" id="demo-select-small" name="Interprete"
    value={Interprete} label="Interprete" onChange={onChange}
  >      
    {listInterprete.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Nom}</MenuItem>)}
  </Select>
</FormControl>  
  return (
  <FormDialog
    titulo={`Editar Contrato ${registro.Id}`} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tamanio="md" btnDeshabilitado={false} cargando={espera}
    setCargando={setEspera}
  >
    <Stack component="form" noValidate spacing={2} sx={{width:"28rem"}}>
      <Box>
      {listTipo.length!==0 ? selectTipo:null}
      {listSello.length!==0 && Tipo===1? selectSello:null}
      </Box>
      {Tipo===1?
      <Box>
        <TextField
          label="Buscar Interprete" id="auxInter-campo"
          value={auxInter} name="auxInter"
          size="small" sx={{width:"10rem"}} onChange={onChange}
        />
       { cargaInter?<CircularProgress sx={{marginLeft:.5+'rem'}}/> 
        :<IconButton disabled={auxInter.length>=3? false:true} onClick={()=>busqInterprete("")} >
          <SearchIcon />
        </IconButton>}
        {listInterprete.length!==0 ? selectInter:null} 
      </Box>
      :null}
      <TextField
        label="Representante" id="representa-campo"
        value={Representa} name="Representa"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />
      <Box >
      <TextField
        id="date-Ini" label="Fecha Inicio" type="date" size='small'
        value={FIni} sx={{ width: 140,marginRight:.5+'rem' }} name="FIni"
        InputLabelProps={{ shrink: true }} onChange={onChangeDate}
      />
      <TextField
        id="date-Fin" label="Fecha Final" type="date" size='small'        
        value={FFin} sx={{ width: 140 }} name="FFin"
        InputLabelProps={{ shrink: true }} onChange={onChangeDate}
      />
      </Box>
     
      <TextField
        id="datetime-Firma" label="Fecha Firma" size='small'
        type="datetime-local" name="FFirma" value={FFirma} 
        onChange={onChangeDate} sx={{ width: 290 }} 
        InputLabelProps={{ shrink: true, }}
      />
      <TextField
        id="outlined-multiline-static" label="Descripción"
        multiline rows={3} name="Descripcion" onChange={onChange}
        value={Descripcion} size='small'
      />
      <TextField
        id="outlined-multiline-static" label="Observación"
        multiline  value={Observacion} size='small'
        rows={3} name="Observacion" onChange={onChange}
        
      />
      <Grid direction="row" container alignItems={"center"}>
        <FormControlLabel sx={{width:'8rem'}} control={<Checkbox  checked={cancelar} onChange={handleChange} />} label={cancelar?"Cancelado":"Cancelar"} />
        {fchCancela.length!==0?        
          <Typography  > Fecha</Typography>          
        :null}
        {fchCancela.length!==0?        
          <Typography  color="primary" sx={{marginLeft:'.5rem'}}>
            {fchCancela}
          </Typography>          
        :null}
      </Grid>
      {cancelar===true?
        <TextField
          label="Motivo" id="motivo-campo"
          value={motivo} name="motivo"
          size="small" sx={{width:"28rem"}} onChange={onChange}
        />
      :null}
    </Stack>
  </FormDialog>
  )
}

export default EditarContrato