import React,{useState,useEffect} from 'react'
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {TextField,Stack, Grid ,Box,Tooltip,IconButton,CardMedia }from '@mui/material';
import FormDialog from '../../Layout/FormDialog'
import { ErrorGeneral,llamadaApiCarga } from '../../funciones/ClienteAxios'; 
import {toast} from 'react-toastify';
import SelectGen from './SelectGen';

const NuevoSello = ({modalAbierto, setModalAbierto, Buscar,datos}) => {
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const history = useNavigate();
  const usuId =localStorage.getItem('UsuId') ;  
  const source = axios.CancelToken.source();            
  const [espera,setEspera]= useState(false); 
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)  
  const [contadorToast,setContadorToast]= useState(0)  
  const [colorSelec3,setColorSelec3] = useState("#FFF")
  const [genero,setGenero]= useState({ Nombre:"", Alias:"", Tel:"",
    Email:"", Representa:'',
 })
  const{  Representa, Nombre, Alias, Tel, Email}=genero
  const[colourOptions,setColourOptions]=useState([])
  useEffect(() => {
    let nvo=[]
    datos.forEach(dato => {
      nvo.push({ value:dato.Id , label: dato.Alias, color: dato.Color })
    });
    setColourOptions(nvo)

    return () => {
    
    }
  }, [])
  
  const llamadaApi = () => {                   
    
    let data = qs.stringify({
        Usuario:usuId, Nombre:Nombre, Alias:Alias, 
        Representa:Representa, Tel:Tel, Email:Email,
        idDispositivo: auxDispV,
    });
    
    let url = "contrato-sello-new";
    function respuesta(auxiliar){            
      if ( auxiliar) 
      {                 
        //Buscar()
        let mensaje="Se creo correctamente el registro"        
        toast.success(`${mensaje}`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-env`
        })  

        setModalAbierto(false)    
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
  };  
 
  const onChange=(e)=>{    
    
    setGenero({...genero,[e.target.name] : e.target.value})
      
  }

  const verificaNulos = (campo,descripcion)=>{
    let respuesta =false;              
    if( (typeof campo !== 'number' && typeof campo === 'string' 
        && (campo.trim() === '' || campo.trim() === null) )
    ) 
    { let mensaje='No se lleno el campo '+descripcion      
      toast.warning(`${mensaje}`,{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `error-env ${contadorToast}`
      })  
      setContadorToast(contadorToast+1)
      respuesta =true
    }    
    return(respuesta);
  }

  const guardarDatos=()=>{    
       
    if (Representa.length>=3 ||  !verificaNulos("","Representante")) 
    {
              
      llamadaApi()
      
    }
   
    
  }
  
  const inputProps = {
    maxlength: "10"
  };

  function cambiarColor3(e){
    setColorSelec3(e.target.value)
  }


  return (
  <FormDialog
    titulo="Nuevo Sello" modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tamanio="md" btnDeshabilitado={false} cargando={espera}
    setCargando={setEspera}
  >
    <Box sx={{ marginBottom:1+'rem', height:window.innerHeight/2}}>
      <SelectGen colourOptions={colourOptions} />
    
    <Box style={{marginTop:'.2em',marginRight:.3+'rem' }}>
      <input type="color" name="color" onChange={cambiarColor3} id='cambiarColor3' hidden/>
      <label htmlFor='cambiarColor3'>
        <Tooltip title={`Editar color del Nombre`}>
          <IconButton component="span" size="small">
            <CardMedia title="icono-colores" style={{width:25+'px',height:25+'px'}}
              image="CIRCULO DE PUNTOS20221222.svg" />            
          </IconButton>
        </Tooltip>
      </label>
    </Box>
    <TextField
      label="Nombre" id="Nombre-campo"
      value={Nombre} name="Nombre"
      size="small" sx={{width:"28rem"}} onChange={onChange}
    /> 
    </Box>
  </FormDialog>
  )
}

export default NuevoSello